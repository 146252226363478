import React from "react"
import Layout from "../components/layout/layout"
import PageLayout from "../components/layout/page/page"
import ContactForm from "../components/contact/contact-form"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <PageLayout name="Contact" />
    <ContactForm />
  </Layout>
)

export default Contact
