/* eslint-disable */
import React, { Component } from "react"

class ContactForm extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleSubmit = e => {
    const body = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.message,
    }

    const endpoint = `https://7atxu58xa3.execute-api.us-east-1.amazonaws.com/prod/wpc-contact-form-logic`

    const endpointRequest = new Request(endpoint, {
      method: "POST",
      body: JSON.stringify(body),
    })
    fetch(endpointRequest)
      .then(response => {
        console.log(response.status)
        if (response.status === 200) {
          this.setState({
            name: "",
            email: "",
            message: "",
            subject: "",
          })
          document.getElementById("success").innerHTML =
            "Thanks, We received your message."
        } else {
          document.getElementById("wrong").innerHTML = "Something wrong."
        }
      })
      .catch(error => {
        console.log("error")
      })

    e.preventDefault()
  }

  render() {
    let { name, email, subject, message } = this.state
    return (
      <div className="contact rounded mx-auto d-block">
        <form className="contact-form" onSubmit={this.handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              className="form-control"
              placeholder="Your Name"
              onChange={this.handleChange}
              value={name}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Your Email"
              onChange={this.handleChange}
              value={email}
              required
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="subject"
              placeholder="Subject"
              onChange={this.handleChange}
              value={subject}
              required
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              name="message"
              rows="5"
              placeholder="Message"
              onChange={this.handleChange}
              value={message}
              required
            ></textarea>
          </div>
          <div className="text-center">
            <button type="submit" className="me-button">
              <a className="cta">
                <span className="myover">Send Message</span>
                <svg width="13px" height="10px" viewBox="0 0 13 10">
                  <path d="M1,5 L11,5"></path>
                  <polyline points="8 1 12 5 8 9"></polyline>
                </svg>
              </a>
            </button>
          </div>
          <div className="abel" id="success"></div>
          <div className="abel" id="wrong"></div>
        </form>
      </div>
    )
  }
}

export default ContactForm
